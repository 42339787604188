/*
 * ReportsTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file ReportsTable.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useState } from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate, timeSince } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import Button from 'react-bootstrap/Button';
import { translate } from '../../../Utils/Translate';
import { IReport } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { Link } from 'react-router-dom';
import { ReportStatus } from '@abstract/abstractwebcommon-shared/enum/license/report';
import { RouteName } from '../../../Utils/routesNames';

/**
 * @interface IReportsTableProperties
 */
interface IReportsTableProperties {
  reports: IReport[] | null /**< reports list. */;
  handleAdd: () => void /**< Open dialog record form. */;
  isLoading: boolean /**< True if loading and false otherwise.*/;
  onSort: (payload: ITablePayload) => void /**< Sort reports table. */;
  onFilter: (searchTerm: string) => void /**< Filter reports table. */;
  handlePageUpdate: (event: any) => void /**< Handle page update. */;
  tablePayload: ITablePayload /**  Table payload. */;
  totalRecords: number /** Total records */;
}

/**
 * File records Table component.
 */
const ReportsTable = (properties: IReportsTableProperties): ReactElement => {
  const tablePayload: ITablePayload = properties.tablePayload;
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>[]>([]);

  /// Triggers on rowClick
  const onRowClick = () => {
    setExpandedRows([]);
  };

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <Button className="d-flex align-items-center mr-2" onClick={properties.handleAdd}>
          <i className="bi bi-plus btn-icon"></i>
          {translate('table_buttons.reportIssue')}
        </Button>
      </div>
    </div>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows([event.data]);
    }
  };

  return (
    <BaseDatatable
      value={properties.reports || []}
      totalRecords={properties.totalRecords}
      isLoading={!properties.reports}
      header={header}
      parentClass="reportsDataTable" /**< ClassName for div Component.*/
      first={tablePayload.skip}
      emptyMessage={translate('client.page.reports.table.empty_msg')}
      rows={tablePayload.limit}
      bodyStyle={'text-center'}
      onPage={properties.handlePageUpdate}
      onRowClick={onRowClick}
      onRowExpand={expandRow}
      onRowCollapse={() => setExpandedRows([])}
      expandedRows={expandedRows}>
      {/* Used to prevent row selection on row click */}
      <Column selectionMode="multiple" className="d-none" />
      <Column
        body={() => {
          return <>&nbsp;</>;
        }}
        className="p-0 col-width-3 d-lg-none d-md-none d-sm-none"
      />
      <Column
        field="title"
        body={(rowData: IReport) => (
          <DatatableColumn
            title={translate('client.page.reports.table.title')}
            data={rowData.title}
          />
        )}
        header={translate('client.page.reports.table.title')}
        className="col-width-20"
      />
      <Column
        field="status"
        header={translate('client.page.reports.table.status')}
        body={(rowData: IReport) => (
          <DatatableColumn
            title={translate('client.page.reports.table.status')}
            data={rowData.status}
          />
        )}
        className="col-width-12 text-nowrap"
      />
      <Column
        field="lastReply"
        body={(rowData: IReport) => (
          <DatatableColumn
            title={translate('I18N.admin.page.reports.table.lastReply')}
            data={rowData.lastReply && timeSince(new Date(rowData.lastReply))}
          />
        )}
        header={translate('I18N.admin.page.reports.table.lastReply')}
        className="col-width-10 text-nowrap d-sm-none d-md-table-cell"
        headerClassName="col-width-10 text-nowrap d-sm-none d-md-table-cell"
      />
      <Column
        field="created"
        header={translate('client.page.reports.table.created')}
        body={(rowData: IReport) => (
          <DatatableColumn
            title={translate('client.page.reports.table.created')}
            data={formatDate(rowData.created)}
          />
        )}
        className="col-width-7"
        headerClassName="col-width-7"
      />
      <Column
        field="open"
        className="col-width-3 absolute-position-responsive-screen"
        body={(rowData: IReport) => {
          return (
            <>
              <Link
                to={`${RouteName.technicalSupportRoute}/${rowData.reportUUID}`}
                className={
                  rowData.status === ReportStatus.done
                    ? 'custom-action-column-action-position m-md-0 mt-1 mr-2 disabled-edit-button'
                    : 'custom-action-column-action-position m-md-0 mt-1 mr-2'
                }>
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Link>
            </>
          );
        }}
      />
    </BaseDatatable>
  );
};

export default ReportsTable;
